<script>
import {get_device_info, get_account_info} from "@/api/devices";

export default {
  name: "DeviceInfo",
  data(){
    return {
      device_id: '',
      device_type: 'android',
      options:[
        {
          label: '电视盒⼦',
          value: 'android'
        },
        {
          label: '电源盒⼦',
          value: 'arm64'
        }
      ],
      status: true,
      device_info:{
        device_id: null,
        isp: null,
        msg: null,
        url: '',
        short_url: '',
        area: null,
      },
      account_info:{
        user: null,
        pwd: null,
        status: false,
        server: null,
      },
      data_flag: false
    }
  },
  methods:{
    query_device_info(){
      this.data_flag = false
      let req_params = {
        device_id : this.device_id,
      }
      get_device_info(req_params).then(res=>{
        this.data_flag = true
        this.status = res.status
        this.device_info = res.data
        this.device_info['short_url'] = '***'
      }).catch(error=>{
        console.error(error)
        this.data_flag = false
      })
      let params = {
        device_id: this.device_id,
        device_type: this.device_type,
      }
      get_account_info(params).then(res=>{
        if(res.status){
          this.account_info = res
        }
      })
    },
    copy(){
      let text = this.device_info.url
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(() => {
          this.$message.success('复制成功')
          console.log('Text copied to clipboard');
        }).catch(err => {
          console.error('Failed to copy text: ', err);
        });
      } else {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        try {
          document.execCommand('copy');
          this.$message.success('复制成功')
        } catch (err) {
          console.error('Failed to copy text: ', err);
        }
        document.body.removeChild(textarea);
      }
    },
    open_link(){
      if(!this.status){
        this.$confirm('检测到当前设备状态异常,是否打开页面?', '警告', {
          confirmButtonText: '打开',
          cancelButtonText: '关闭',
          type: 'warning'
        }).then(() => {
          window.open(this.device_info.url, '_blank');
          // 执行删除操作
        }).catch(() => {
          // 取消操作
        });
      }
      else{
        window.open(this.device_info.url, '_blank');
      }

    },
    clear_data(){
      this.data_flag = false
    }
  },
  computed:{
    btn_status(){
      return !(this.status && this.account_info.status)
    }
  }
}
</script>

<template>
  <div>
    <el-row>
      <el-col :span="4" :offset="10"> 云盒穿透小工具</el-col>
    </el-row>
    <el-row>
      <el-col :span="4" :offset="1">
        <el-input v-model="device_id" placeholder="请输入序列号" size="small" clearable @focus="clear_data"></el-input>
      </el-col>
      <el-col :span="4" :offset="1">
        <el-select v-model="device_type" placeholder="请选择设备类型" size="small" clearable>
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            <span style="float: left">{{ item.label }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4" :offset="1">
        <el-button size="small" type="primary" @click="query_device_info">查找</el-button>
      </el-col>
    </el-row>
    <el-row v-if="data_flag">
      <el-col :span="22" :offset="1">
        <el-card class="box-card" shadow="hover">
          <el-descriptions class="margin-top" title="设备信息" :column="3">
            <template slot="extra">
              <el-button type="primary" size="mini" @click="copy">复制</el-button>
            </template>
            <el-descriptions-item label="序列号">{{device_info.device_id}}</el-descriptions-item>
            <el-descriptions-item label="运营商">{{device_info.isp}}</el-descriptions-item>
            <el-descriptions-item label="是否可用">
              <el-switch
                  v-model="status"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  disabled
              >
              </el-switch>

            </el-descriptions-item>
              <el-descriptions-item label="公网地址" >
                <el-tooltip class="item" effect="dark" :content="device_info.url" placement="top-start">
                  <label>***</label>
                </el-tooltip>
              </el-descriptions-item>
            <el-descriptions-item label="状态信息">{{device_info.msg}}</el-descriptions-item>
            <el-descriptions-item label="区域">{{device_info.area}}</el-descriptions-item>
          </el-descriptions>
          <el-divider></el-divider>
          <el-descriptions class="margin-top" title="账户信息" :column="4">
            <el-descriptions-item label="用户名">{{account_info.user}}</el-descriptions-item>
            <el-descriptions-item label="密码">{{account_info.pwd}}</el-descriptions-item>
            <el-descriptions-item label="是否可用">
              <el-switch
                  v-model="account_info.status"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  disabled
              >
              </el-switch>

            </el-descriptions-item>
            <el-descriptions-item label="ip地址" >{{account_info.server}}</el-descriptions-item>
          </el-descriptions>
          <el-row>
            <el-col :span="4" :offset="18">
              <el-button size="mini" type="primary" :disabled="btn_status" @click="open_link">打开</el-button>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <el-row v-else>
      <el-empty description="未查询到对应的设备信息"></el-empty>
    </el-row>
  </div>
</template>

<style scoped>
.el-row {
  margin-bottom: 15px;
}
</style>