import axios from 'axios'
import {Message as $message} from 'element-ui';

function requests(config) {
    let header = {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control':'no-cache'
    }
    const instance = axios.create({
        baseURL: 'https://toumi-api.westreamers.com',
        // baseURL: 'http://172.16.253.9:5001',
        timeout: 40000,
        headers: header
    })
    // 请求拦截
    instance.interceptors.request.use(config => {
        return config
    }, error => {
        $message.error('获取数据失败,请稍后在试试...')
    })
    // 响应拦截
    instance.interceptors.response.use(response => {
        return response.data
    }, error => {
        $message.error(error.toString())
        console.log('error:',error)
    })
    return instance(config)
}

export {requests}
