import {requests} from "@/api/requests";
function get_device_info(params){
    return requests({
        url: '/base/device/url',
        params: params,
        method: 'get'
    })
}

function get_account_info(params){
    return requests({
        url: '/base/device/account/info',
        params: params,
        method: 'get'
    })
}
export {
    get_device_info,
    get_account_info
}